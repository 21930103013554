<template>
	<div class="lac_detail">
		<div class="child-top lac_detail_top">
			<div class="input-from lac_detail_top_info">
				<el-avatar :size="50">
					<img :src="infoObj.img" />
				</el-avatar>
				<span>姓名:</span>{{infoObj.name}} <span>性别:</span>{{infoObj.sex == 1 ? '男' : '女'}}<span>身高:</span>{{infoObj.height}}cm <span>体重:</span>{{infoObj.weight}}kg <span>所属运动队:</span>{{infoObj.sport_team}} <span>部门:</span>{{infoObj.department_name}}
			</div>
			<div class="lac_detail_top_btn">
				<el-button class="new_btn" @click="goHistoryList" operation-button round>个人历史数据</el-button>
				<span
					class="fanhui"
					@click="
						() => {
							$router.push({ path: '/textItem/lactateText', query: { type: 7 } })
						}
					"
					><i class="iconfont iconfanhui"></i>返回</span
				>
			</div>
		</div>

		<div class="lac_detail_content">
			<div class="content_title">
				<i class="iconfont icona-6"></i> 数据分析
				<div class="title_tabs">
					<div :class="active == 0 ? 'active' : ''" @click="tabsClick(0)">机能状态分析</div>
					<div :class="active == 1 ? 'active' : ''" @click="tabsClick(1)">有氧能力分析</div>
					<div :class="active == 2 ? 'active' : ''" @click="tabsClick(2)">无氧能力分析</div>
				</div>
			</div>

			<div class="content_top_view" v-show="active == 2">
				<div class="top_view_tips">
					<span></span>
					<p>通过能力指标，评估运动员的训练效果；</p>
					<p>
						通过机能指标，评估运动员适应情况，预防过度训练；
					</p>
				</div>
				<div style="display: flex;">
					<div class="view_tips_left" id="view_tips_left_echarts"></div>
					<div class="view_tips_right">
						<el-table :data="wyTableData" style="width: 100%" height="250" stripe>
							<el-table-column prop="date" label="测试日期" align="center"> </el-table-column>
							<el-table-column prop="max" label="血乳酸最高值 (mmol/L)" align="center"> </el-table-column>
							<el-table-column prop="time" label=" 达最高血乳酸时间(min) " align="center"> </el-table-column>
						</el-table>
					</div>
				</div>
			</div>

			<div class="content_top_view" v-show="active == 1">
				<div class="top_view_tips">
					<span></span>
					<p>通过训练后血乳酸清除速度，评价运动员有氧能力；</p>
					<p>
						血乳酸清除越快，有氧恢复能力越强。
					</p>
				</div>
				<div style="display: flex;">
					<div class="view_tips_left" id="view_tips_center_echarts"></div>
					<div class="view_tips_right">
						<el-table :data="yyTableData" style="width: 100%" height="250" stripe>
							<el-table-column prop="date" label="测试日期" align="center"> </el-table-column>
							<el-table-column prop="max" label="血乳酸清除速度（min）" align="center"> </el-table-column>
							<el-table-column prop="time" label="血乳酸清除时间（min）" align="center"> </el-table-column>
						</el-table>
					</div>
				</div>
			</div>

			<div class="content_top_view" v-show="active == 0">
				<div class="top_view_tips">
					<span></span>
					<p>通过能力指标，评估运动员的训练效果；</p>
					<p>
						通过机能指标，评估运动员适应情况，预防过度训练
					</p>
				</div>
				<div style="display: flex;">
					<div class="view_tips_left">
						<div class="view_tips_left_tootips"><span></span> {{ jnTableData[0].date }} <span></span> {{ jnTableData[1].date }} <span></span> 差值</div>
						<div style="display: flex;">
							<div class="view_tips_first_echarts" id="view_tips_firstLeft_echarts"></div>
							<div class="view_tips_first_echarts" id="view_tips_firstRight_echarts"></div>
						</div>
						<div style="display: flex;">
							<div class="echsrts_title">训练成绩</div>
							<div class="echsrts_title">血乳酸值</div>
						</div>
					</div>
					<div class="view_tips_right">
						<el-table :data="jnTableData" style="width: 100%" height="250" stripe>
							<el-table-column prop="date" label="测试日期" align="center"> </el-table-column>
							<el-table-column prop="max" label="2000m皮艇（s）" align="center"> </el-table-column>
							<el-table-column prop="time" label="血乳酸值（mmol/L）" align="center"> </el-table-column>
						</el-table>
					</div>
				</div>
			</div>

			<div class="content_title"><i class="iconfont icona-5"></i> 测试数据</div>

			<div class="content_bot_view" v-show="active == 2">
				<div class="content_bot_view_left">
					<div class="view_left_info">
						<span>1</span>
						<ul>
							<li><span>标 题</span> 400m跑测试</li>
							<li><span>测试日期</span> 2023-08-15</li>
							<li><span>训练内容</span> 400m （s）* 5</li>
						</ul>
					</div>
					<div class="view_left_echarts">
						<div class="left_table">
							<!-- <el-radio-group v-model="radio1" @change="radioChange('radio1')"> -->
							<el-table :data="wyTableData1" style="width: 100%" height="320" stripe>
								<el-table-column prop="" label="序号" align="center" width="48.5">
									<template slot-scope="scope">
										{{ scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column prop="minuse" label="400m （s）* 5" align="center" width="50"> </el-table-column>
								<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
								<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
								<!-- <el-table-column prop="" label="选择分析数据" align="center" width="63">
										<template slot-scope="scope">
											<el-radio :label="scope.$index" name="">{{ '' }}</el-radio>
										</template>
									</el-table-column> -->
							</el-table>
							<!-- </el-radio-group> -->
						</div>
						<div class="left_echarts" id="wyleftEcharts"></div>
					</div>
				</div>
				<div class="content_bot_view_left">
					<div class="view_left_info">
						<span>2</span>
						<ul>
							<li><span>标 题</span> 400m跑测试</li>
							<li><span>测试日期</span> 2023-09-10</li>
							<li><span>训练内容</span> 400m （s）* 5</li>
						</ul>
					</div>
					<div class="view_left_echarts">
						<div class="left_table">
							<!-- <el-radio-group v-model="radio2" @change="radioChange('radio2')"> -->
							<el-table :data="wyTableData2" style="width: 100%" height="320" stripe>
								<el-table-column prop="" label="序号" align="center" width="48.5">
									<template slot-scope="scope">
										{{ scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column prop="minuse" label="400m （s）* 5" align="center" width="50"> </el-table-column>
								<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
								<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
								<!-- <el-table-column prop="" label="选择分析数据" align="center" width="63">
										<template slot-scope="scope">
											<el-radio :label="scope.$index" name="">{{ '' }}</el-radio>
										</template>
									</el-table-column> -->
							</el-table>
							<!-- </el-radio-group> -->
						</div>
						<div class="left_echarts" id="wyrightEcharts"></div>
					</div>
				</div>
			</div>

			<div class="content_bot_view" v-show="active == 1">
				<div class="content_bot_view_left">
					<div class="view_left_info">
						<span>1</span>
						<ul>
							<li><span>标 题</span> 递增负荷测试</li>
							<li><span>测试日期</span> 2023-08-15</li>
							<li><span>训练内容</span> 功率自行车</li>
						</ul>
					</div>
					<div class="view_left_echarts">
						<div class="left_table">
							<!-- <el-radio-group v-model="radio1" @change="radioChange('radio1')"> -->
							<el-table :data="yyTableData1" style="width: 100%" height="320" stripe>
								<el-table-column prop="" label="序号" align="center" width="48.5">
									<template slot-scope="scope">
										{{ scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column prop="power" label="功率(W)" align="center" width="55"> </el-table-column>
								<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
								<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
								<!-- <el-table-column prop="" label="选择分析数据" align="center" width="63">
										<template slot-scope="scope">
											<el-radio :label="scope.$index" name="">{{ '' }}</el-radio>
										</template>
									</el-table-column> -->
							</el-table>
							<!-- </el-radio-group> -->
						</div>
						<div class="left_echarts" id="yyleftEcharts"></div>
					</div>
				</div>
				<div class="content_bot_view_left">
					<div class="view_left_info">
						<span>2</span>
						<ul>
							<li><span>标 题</span> 递增负荷测试</li>
							<li><span>测试日期</span> 2023-09-10</li>
							<li><span>训练内容</span> 功率自行车</li>
						</ul>
					</div>
					<div class="view_left_echarts">
						<div class="left_table">
							<!-- <el-radio-group v-model="radio2" @change="radioChange('radio2')"> -->
							<el-table :data="yyTableData2" style="width: 100%" height="320" stripe>
								<el-table-column prop="" label="序号" align="center" width="48.5">
									<template slot-scope="scope">
										{{ scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column prop="power" label="功率(W)" align="center" width="55"> </el-table-column>
								<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
								<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
								<!-- <el-table-column prop="" label="选择分析数据" align="center" width="63">
										<template slot-scope="scope">
											<el-radio :label="scope.$index" name="">{{ '' }}</el-radio>
										</template>
									</el-table-column> -->
							</el-table>
							<!-- </el-radio-group> -->
						</div>
						<div class="left_echarts" id="yyrightEcharts"></div>
					</div>
				</div>
			</div>

			<div class="content_bot_view_first" v-show="active == 0">
				<div class="content_bot_view_left">
					<div class="view_left_echarts">
						<div class="left_echarts" id="right_first_Echarts"></div>
						<div class="left_table">
							<el-table :data="jnTableData1" style="width: 97%" height="320" stripe>
								<el-table-column prop="" label="序号" align="center">
									<template slot-scope="scope">
										{{ scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column prop="title" label="标题" align="center"> </el-table-column>
								<el-table-column prop="date" label="测试日期" align="center"> </el-table-column>
								<el-table-column prop="minuse" label="2000m皮艇（s）" align="center"> </el-table-column>
								<el-table-column prop="value" label="血乳酸值 (mmol/L)" align="center"> </el-table-column>
								<el-table-column prop="time" label="血乳酸测试时间" align="center"> </el-table-column>
								<!-- <el-table-column prop="" label="选择分析数据" align="center"> </el-table-column> -->
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: 0,
			radio1: '',
			radio2: '',
			jnTableData: [
				{ date: '2023-08-15', max: '502.4', time: '7.49', diff: '3.9' },
				{ date: '2023-09-10', max: '498.5', time: '7.83', diff: '-0.34' },
			],
			jnTableData1: [
				{ title: '男子皮艇2000米测试', date: '2023-08-15', minuse: '502.4', value: '7.49', time: '13:00' },
				{ title: '男子皮艇2000米测试', date: '2023-09-10', minuse: '498.5', value: '7.83', time: '14:00' },
			],

			yyTableData: [
				{ date: '2023-08-15', max: '0.31', time: '13' },
				{ date: '2023-09-10', max: '0.19', time: '13' },
			],
			yyTableData1: [
				{ power: '50', value: '2.12', time: '13:00' },
				{ power: '100', value: '3.23', time: '13:03' },
				{ power: '150', value: '4.32', time: '13:08' },
				{ power: '200', value: '6.97', time: '13:15' },
				{ power: '250', value: '10.04', time: '13:23' },
				{ power: '运动后2min', value: '9.67', time: '13:25' },
				{ power: '运动后5min', value: '9.27', time: '13:28' },
				{ power: '运动后8min', value: '8.82', time: '13:31' },
				{ power: '运动后10min', value: '8.20', time: '13:33' },
				{ power: '运动后15min', value: '7.25', time: '13:38' },
			],
			yyTableData2: [
				{ power: '50', value: '2.55', time: '13:00' },
				{ power: '100', value: '2.92', time: '13:05' },
				{ power: '150', value: '4.82', time: '13:13' },
				{ power: '200', value: '6.76', time: '13:18' },
				{ power: '250', value: '9.15', time: '13:26' },
				{ power: '运动后2min', value: '13.21', time: '13:28' },
				{ power: '运动后5min', value: '12.52', time: '13:31' },
				{ power: '运动后8min', value: '11.16', time: '13:34' },
				{ power: '运动后10min', value: '10.29', time: '13:36' },
				{ power: '运动后15min', value: '9.14', time: '13:41' },
			],

			wyTableData: [
				{ date: '2023-08-15', max: '15.4', time: '14' },
				{ date: '2023-09-10', max: '15.5', time: '15' },
			],
			wyTableData1: [
				{ minuse: '74.5', value: '13.3', time: '13:00' },
				{ minuse: '75.1', value: '13.7', time: '13:04' },
				{ minuse: '76.6', value: '14.9', time: '13:07' },
				{ minuse: '78.2', value: '15.1', time: '13:10' },
				{ minuse: '79.3', value: '15.4', time: '13:14' },
			],
			wyTableData2: [
				{ minuse: '75.3', value: '13.5', time: '13:00' },
				{ minuse: '76.9', value: '14.0', time: '13:03' },
				{ minuse: '77.8', value: '14.4', time: '13:07' },
				{ minuse: '79.6', value: '15.3', time: '13:11' },
				{ minuse: '81.7', value: '15.5', time: '13:15' },
			],
			infoObj: {}
		}
	},
	mounted() {
		this.infoObj = {
			name: this.$route.query.name,
			sex: this.$route.query.sex,
			old: this.$route.query.old,
			height: this.$route.query.height,
			weight: this.$route.query.weight,
			img: this.$route.query.img,
			sport_team: this.$route.query.sport_team,
			department_name: this.$route.query.department_name
		}
		this.setFirstEcharts()
		this.setBarFirstEcharts()
	},
	methods: {
		radioChange() {
			console.log(this.radio2)
		},
		goHistoryList() {
			this.$router.push({path: '/textItem/lactateTextList', query: {
				img: this.infoObj.img,
				name: this.infoObj.name,
				sex: this.infoObj.sex,
				old: this.infoObj.old,
				height: this.infoObj.height,
				weight: this.infoObj.weight,
				sport_team: this.infoObj.sport_team,
				department_name: this.infoObj.department_name
			}})
		},
		tabsClick(value) {
			this.active = value
			if (value == 1) {
				this.$nextTick(() => {
					this.$echarts.init(document.getElementById('right_first_Echarts')).dispose()
					this.$echarts.init(document.getElementById('wyrightEcharts')).dispose()
					this.$echarts.init(document.getElementById('wyleftEcharts')).dispose()
					this.$echarts.init(document.getElementById('view_tips_left_echarts')).dispose()
					this.setLastEcharts('view_tips_center_echarts')
					this.setBarEcharts()
				})
			} else if (value == 2) {
				this.$nextTick(() => {
					this.$echarts.init(document.getElementById('right_first_Echarts')).dispose()
					this.$echarts.init(document.getElementById('yyrightEcharts')).dispose()
					this.$echarts.init(document.getElementById('yyleftEcharts')).dispose()
					this.$echarts.init(document.getElementById('view_tips_center_echarts')).dispose()
					this.setLastEcharts('view_tips_left_echarts')
					this.setLastBarEcharts()
				})
			} else if (value == 0) {
				this.$echarts.init(document.getElementById('view_tips_center_echarts')).dispose()
				this.$echarts.init(document.getElementById('view_tips_left_echarts')).dispose()
				this.$nextTick(() => {
					this.setFirstEcharts()
					this.setBarFirstEcharts()
				})
			}
		},
		setBarEcharts() {
			// { power: '50', value: '2.55', time: '13:00' },
			// 	{ power: '100', value: '2.92', time: '13:05' },
			// 	{ power: '150', value: '4.82', time: '13:13' },
			// 	{ power: '200', value: '6.76', time: '13:18' },
			// 	{ power: '250', value: '9.15', time: '13:26' },
			// 	{ power: '运动后2min', value: '13.21', time: '13:28' },
			// 	{ power: '运动后5min', value: '12.52', time: '13:31' },
			// 	{ power: '运动后8min', value: '11.16', time: '13:34' },
			// 	{ power: '运动后10min', value: '10.29', time: '13:36' },
			// 	{ power: '运动后15min', value: '9.14', time: '13:41' },

			// { power: '运动后2min', value: '9.67', time: '13:25' },
			// { power: '运动后5min', value: '9.27', time: '13:28' },
			// { power: '运动后8min', value: '8.82', time: '13:31' },
			// { power: '运动后10min', value: '8.20', time: '13:33' },
			// { power: '运动后15min', value: '7.25', time: '13:38' },
			var xdata = ['运动后2min', '运动后5min', '运动后8min', '运动后10min', '运动后15min'],
				ydata1 = ['13.21', '12.52', '11.16', '10.29', '9.14'],
				xdata1 = [],
				ydata = ['9.67', '9.27', '8.82', '8.20', '7.25']
			// this.yyTableData1.forEach(item => {
			// 	ydata.push(item.value)
			// 	xdata.push(item.power)
			// })
			// this.yyTableData2.forEach(item => {
			// 	ydata1.push(item.value)
			// 	xdata1.push(item.power)
			// })

			let leftserum = this.$echarts.init(document.getElementById('yyleftEcharts'))
			let rightserum = this.$echarts.init(document.getElementById('yyrightEcharts'))

			var options = {
				legend: {
					data: ['血乳酸值'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '5%',
					bottom: '3%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
							rotate: 45,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						nameTextStyle: {
							padding: [0, 0, 0, 60],
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
						},
					},
				],
				series: [
					{
						name: '血乳酸值',
						type: 'line',
						tooltip: {
							valueFormatter: function(value) {
								return value
							},
						},
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						data: ydata,
					},
				],
			}
			leftserum.setOption(options)
			this.$echartsResize(leftserum)

			var options1 = {
				legend: {
					data: ['血乳酸值'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '5%',
					bottom: '3%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
							rotate: 45,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						nameTextStyle: {
							padding: [0, 0, 0, 60],
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
						},
					},
				],
				series: [
					{
						name: '血乳酸值',
						type: 'line',
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						data: ydata1,
					},
				],
			}
			rightserum.setOption(options1)
			this.$echartsResize(rightserum)
		},
		setLastBarEcharts() {
			var xdata = [],
				ydata = [],
				ydatas = [],
				xdata1 = [],
				ydata1 = [],
				ydatas1 = []
			this.wyTableData1.forEach(item => {
				ydata.push(item.value)
				ydatas.push(item.minuse)
				xdata.push(item.time)
			})
			this.wyTableData2.forEach(item => {
				ydata1.push(item.value)
				ydatas1.push(item.minuse)
				xdata1.push(item.time)
			})

			let leftserum = this.$echarts.init(document.getElementById('wyleftEcharts'))
			let rightserum = this.$echarts.init(document.getElementById('wyrightEcharts'))

			var options = {
				legend: {
					data: ['400m', '血乳酸值'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '10%',
					bottom: '3%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '400m（s）*5',
						nameTextStyle: {
							color: '#000',
						},
						padding: [0, 0, 0, 40],
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						padding: [0, 40, 0, 0],
						nameTextStyle: {
							color: '#000',
						},
						nameLocation: 'end',
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						name: '400m',
						type: 'bar',
						barWidth: 16,
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: ydatas,
					},
					{
						name: '血乳酸值',
						type: 'line',
						yAxisIndex: 1,
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						data: ydata,
					},
				],
			}
			leftserum.setOption(options)
			this.$echartsResize(leftserum)

			var options1 = {
				legend: {
					data: ['400m', '血乳酸值'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '10%',
					bottom: '3%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '400m（s）*5',
						nameTextStyle: {
							color: '#000',
						},
						padding: [0, 0, 0, 40],
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						padding: [0, 40, 0, 0],
						nameTextStyle: {
							color: '#000',
						},
						nameLocation: 'end',
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						name: '400m',
						type: 'bar',
						barWidth: 16,
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: ydatas1,
					},
					{
						name: '血乳酸值',
						type: 'line',
						yAxisIndex: 1,
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						data: ydata1,
					},
				],
			}
			rightserum.setOption(options1)
			this.$echartsResize(rightserum)
		},

		setBarFirstEcharts() {
			var ydata1 = [],
				ydata2 = [],
				xdata = [],
				day = ['第一次', '第二次', '第三次', '第四次', '第五次', '第六次', '第七次', '第八次', '第九次', '第十次']
			this.jnTableData1.forEach((item, index) => {
				xdata.push(day[index])
				ydata1.push(item.minuse)
				ydata2.push(item.value)
			})
			let leftserum = this.$echarts.init(document.getElementById('right_first_Echarts'))
			var options = {
				legend: {
					data: ['2000m皮艇', '血乳酸值'],
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '5%',
					right: '10%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#000',
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '2000m皮艇（s）',
						nameTextStyle: {
							color: '#000',
						},
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						nameLocation: 'end',
						nameTextStyle: {
							color: '#000',
						},
						min: 0,
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						name: '2000m皮艇',
						type: 'bar',
						barWidth: 16,
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: ydata1,
					},
					{
						name: '血乳酸值',
						type: 'line',
						yAxisIndex: 1,
						color: '#FFB835',
						lineStyle: {
							width: 3,
						},
						data: ydata2,
					},
				],
			}
			leftserum.setOption(options)
			this.$echartsResize(leftserum)
		},

		setLastEcharts(name) {
			// { power: '50', value: '2.55', time: '13:00' },
			// 	{ power: '100', value: '2.92', time: '13:05' },
			// 	{ power: '150', value: '4.82', time: '13:13' },
			// 	{ power: '200', value: '6.76', time: '13:18' },
			// 	{ power: '250', value: '9.15', time: '13:26' },
			// 	{ power: '运动后2min', value: '13.21', time: '13:28' },
			// 	{ power: '运动后5min', value: '12.52', time: '13:31' },
			// 	{ power: '运动后8min', value: '11.16', time: '13:34' },
			// 	{ power: '运动后10min', value: '10.29', time: '13:36' },
			// 	{ power: '运动后15min', value: '9.14', time: '13:41' },
			var xdata = [],
				ydata = [],
				xdata1 = [],
				ydata1 = []

			if (name == 'view_tips_left_echarts') {
				var day = ['第一组', '第二组', '第三组', '第四组', '第五组', '第六组', '第七组', '第八组', '第九组', '第十组']
				this.wyTableData1.forEach((item, index) => {
					ydata.push(Number(item.value))
					xdata.push(day[index])
				})
				this.wyTableData2.forEach((item, index) => {
					ydata1.push(Number(item.value))
					// xdata1.push(day[index])
				})
			} else if (name == 'view_tips_center_echarts') {
				xdata = ['50W', '100W', '150W', '200W', '250W', '运动后2min', '运动后5min', '运动后8min', '运动后10min', '运动后15min']
				this.yyTableData1.forEach(item => {
					ydata.push(Number(item.value))
					// xdata.push(item.power)
				})
				this.yyTableData2.forEach(item => {
					ydata1.push(Number(item.value))
					// xdata1.push(item.power)
				})
			}
			let serum = this.$echarts.init(document.getElementById(name))
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				legend: {
					data: ['2023-08-15', '2023-09-10'],
					icon: 'rect',
				},
				grid: {
					left: '10%',
					right: '10%',
					bottom: '3%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					// nameRotate: 90,
					axisLine: {
						show: true,
						lineStyle: {
							color: '#DCDEE3',
						},
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#000',
						rotate: name == 'view_tips_left_echarts' ? '' : 45,
					},
					data: xdata,
				},
				yAxis: {
					type: 'value',
					name: '血乳酸值 (mmol/L)',
					nameTextStyle: {
						color: '#000',
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#DCDEE3',
						},
					},
					axisLabel: {
						color: '#000',
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
				},
				color: ['#00C60C', '#3FA1FF'],
				series: [
					{
						name: '2023-08-15',
						type: 'line',
						// stack: 'Total',
						data: ydata,
						label: {
							show: true,
							position: 'bottom',
						},
						lineStyle: {
							width: 3,
							shadowBlur: 4,
							shadowColor: '#00C60C',
						},
					},
					{
						name: '2023-09-10',
						type: 'line',
						// stack: 'Total',
						data: ydata1,
						label: {
							show: true,
						},
						lineStyle: {
							width: 3,
							shadowBlur: 4,
							shadowColor: '#3FA1FF',
						},
					},
				],
			}
			serum.setOption(options)
			this.$echartsResize(serum)
		},
		setFirstEcharts() {
			let leftserum = this.$echarts.init(document.getElementById('view_tips_firstLeft_echarts'))
			let rightserum = this.$echarts.init(document.getElementById('view_tips_firstRight_echarts'))
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '15%',
					right: '10%',
					bottom: '4%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisLabel: {
							color: '#333',
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '2000m皮艇（s）',
						nameLocation: 'end',
						nameTextStyle: {
							color: '#000',
						},
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisLabel: {
							color: '#333',
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						label: {
							show: true,
							position: 'top',
							color: '00C60C',
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#00E51C', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#00C60C', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[0].max],
					},
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						label: {
							show: true,
							position: 'top',
							color: '#3FA1FF',
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[1].max],
					},
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						label: {
							show: true,
							position: 'top',
							color: '#FFB835',
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#FFDD66', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#FFB835', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[0].diff],
					},
				],
			}
			leftserum.setOption(options)
			this.$echartsResize(leftserum)

			var options2 = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				grid: {
					left: '15%',
					right: '10%',
					bottom: '4%',
					top: '20%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisPointer: {
							type: 'shadow',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '血乳酸值（mmol/L）',
						nameLocation: 'end',
						nameTextStyle: {
							color: '#000',
						},
						axisLabel: {
							formatter: '{value}',
							color: '#000',
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#DCDEE3',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
					},
				],
				series: [
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						label: {
							show: true,
							position: 'top',
							color: '00C60C',
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#00E51C', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#00C60C', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[0].time],
					},
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						label: {
							show: true,
							position: 'top',
							color: '#3FA1FF',
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#73CFFF', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#3FA1FF', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[1].time],
					},
					{
						type: 'bar',
						barWidth: 16,
						barGap: '150%',
						tooltip: {
							// valueFormatter: function(value) {
							// 	return value + ' ml'
							// },
						},
						itemStyle: {
							normal: {
								barBorderRadius: [15, 15, 0, 0],
							},
						},
						label: {
							show: true,
							position: 'top',
							color: '#FFB835',
						},
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [
								{
									offset: 0,
									color: '#FFDD66', // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#FFB835', // 100% 处的颜色
								},
							],
						},
						data: [this.jnTableData[1].diff],
					},
				],
			}
			rightserum.setOption(options2)
			this.$echartsResize(rightserum)
		},
	},
}
</script>

<style lang="scss" scoped>
.lac_detail {
	.lac_detail_top {
		background: #fff;
		border-radius: 5px;
		padding: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.lac_detail_top_info {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333333;
			span {
				margin: 0 5px 0 20px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
		.lac_detail_top_btn {
			.new_btn {
				background: #1a9f16;
				color: #fff;
				border: none;
				margin-right: 20px;
			}
			.fanhui {
				font-size: 16px;
				color: #666;
				display: inline-block;
				padding-top: 5px;
				margin-left: 40px;
				margin-right: 20px;
				i {
					margin-right: 5px;
					color: #000;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
		.operation-button {
		}
	}
	.lac_detail_content {
		background: #fff;
		margin-top: 15px;
		padding: 10px 15px 10px;
		border-radius: 5px;
		.content_title {
			font-size: 16px;
			font-weight: 500;
			color: #333333;
			position: relative;
			i {
				color: #0055e9;
				margin-right: 5px;
				font-size: 18px;
			}
			.title_tabs {
				position: absolute;
				top: 0px;
				left: 50%;
				display: flex;
				transform: translateX(-50%);
				div {
					padding: 8px 17px;
					box-shadow: 2px 2px 8px 0px #dfdfdf;
					border-radius: 24px;
					border: 1px solid #eeeeee;
					margin-right: 15px;
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						cursor: pointer;
					}
				}
				.active {
					background: #0055e9;
					color: #fff;
					border: 1px solid #0055e9;
				}
			}
		}
		.content_top_view {
			margin-top: 35px;
			margin-bottom: 20px;
			// height: 100px;
			background: #ffffff;
			box-shadow: 0px 2px 7px 4px rgba(230, 230, 230, 0.5);
			border-radius: 10px;
			padding-top: 10px;
			padding-bottom: 15px;
			.top_view_tips {
				width: 400px;
				background: #f1f7ff;
				border-radius: 10px;
				border: 1px solid #0055e9;
				margin: 10px auto;
				position: relative;
				overflow: hidden;
				padding-bottom: 10px;
				span {
					width: 10px;
					height: 100%;
					background: #0055e9;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
				}
				p {
					margin-top: 10px;
					margin-left: 20px;
				}
			}

			.view_tips_left {
				width: 50%;
				height: 300px;

				.view_tips_left_tootips {
					height: 50px;
					line-height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					span {
						display: inline-block;
						width: 15px;
						height: 15px;
						border-radius: 3px;
						margin-right: 10px;
						margin-left: 20px;
						background: #cfc;
						color: #333;
						&:nth-child(1) {
							background: #00c60c;
						}
						&:nth-child(2) {
							background: #3fa1ff;
						}
						&:nth-child(3) {
							background: #ffb835;
						}
					}
				}
				.view_tips_first_echarts {
					flex: 1;
					height: 230px;
				}

				.echsrts_title {
					flex: 1;
					text-align: center;
					font-weight: 600;
					color: #333333;
					font-size: 18px;
				}
			}
			.view_tips_right {
				width: 40%;
				height: 300px;
				margin-left: 5%;
				.el-table {
					border: 1px solid #ececec;
					border-radius: 10px;
					/deep/ .el-table__header > thead > tr > th {
						background: #f1f7ff;
						font-weight: 600;
						color: #73879c;
					}
				}
			}
		}
		.content_bot_view {
			display: flex;
			justify-content: space-between;
			// height: 100px;
			margin-top: 10px;
			> .content_bot_view_left {
				width: 49.5%;
				background: #ffffff;
				box-shadow: 0px 2px 7px 4px rgba(230, 230, 230, 0.5);
				border-radius: 10px;
				.view_left_info {
					margin-top: 20px;
					display: flex;
					> span {
						display: inline-block;
						width: 30px;
						height: 45px;
						background: #0055e9;
						color: #fff;
						text-align: center;
						line-height: 45px;
						font-size: 20px;
					}
					ul {
						flex: 1;
						display: flex;
						li {
							width: 30%;
							margin-left: 2%;
							border-radius: 10px;
							border: 1px solid #3fa1ff;
							height: 45px;
							font-weight: 600;
							color: #73879c;
							> span {
								width: 80px;
								display: inline-block;
								background: #f1f7ff;
								border-radius: 10px 0px 0px 10px;
								border-right: 1px solid #3fa1ff;
								font-weight: 600;
								color: #73879c;
								height: 45px;
								line-height: 45px;
								text-align: center;
								font-size: 16px;
							}
						}
					}
				}

				.view_left_echarts {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					height: 400px;
					padding-left: 10px;
					.el-table {
						border: 1px solid #ececec;
						border-radius: 10px;
						/deep/ .el-table__header > thead > tr > th {
							background: #f1f7ff;
							font-weight: 600;
							color: #73879c;
						}
					}
				}

				.left_table {
					width: 48%;
					height: 400px;
				}

				.left_echarts {
					width: 48%;
					height: 400px;
				}
			}
			.content_bot_view_right {
			}
		}

		.content_bot_view_first {
			display: flex;
			justify-content: space-between;
			// height: 100px;
			margin-top: 10px;
			> .content_bot_view_left {
				width: 100%;
				background: #ffffff;
				box-shadow: 0px 2px 7px 4px rgba(230, 230, 230, 0.5);
				border-radius: 10px;
				.view_left_info {
					margin-top: 20px;
					display: flex;
					> span {
						display: inline-block;
						width: 30px;
						height: 45px;
						background: #0055e9;
						color: #fff;
						text-align: center;
						line-height: 45px;
						font-size: 20px;
					}
					ul {
						flex: 1;
						display: flex;
						li {
							width: 30%;
							margin-left: 2%;
							border-radius: 10px;
							border: 1px solid #3fa1ff;
							height: 45px;
							font-weight: 600;
							color: #73879c;
							> span {
								width: 80px;
								display: inline-block;
								background: #f1f7ff;
								border-radius: 10px 0px 0px 10px;
								border-right: 1px solid #3fa1ff;
								font-weight: 600;
								color: #73879c;
								height: 45px;
								line-height: 45px;
								text-align: center;
								font-size: 16px;
							}
						}
					}
				}

				.view_left_echarts {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					height: 400px;
					padding-left: 10px;
					.el-table {
						border: 1px solid #ececec;
						border-radius: 10px;
						/deep/ .el-table__header > thead > tr > th {
							background: #f1f7ff;
							font-weight: 600;
							color: #73879c;
						}
					}
				}

				.left_table {
					width: 48%;
					height: 400px;
				}

				.left_echarts {
					width: 48%;
					height: 400px;
				}
			}
			.content_bot_view_right {
			}
		}
	}
}
</style>
